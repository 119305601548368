import { Title, type UseModalState } from "@clipboard-health/ui-react";
import { DialogContent, Stack } from "@mui/material";
import { type Facility } from "@src/appV2/Facilities/types";
import { FullScreenDialog } from "@src/appV2/lib/Dialogs";
import { useEffect } from "react";
import { generatePath } from "react-router-dom";

import { CounterText } from "../../components/CounterText";
import { DialogFooter } from "../../components/DialogFooter";
import { DialogHeader } from "../../components/DialogHeader";
import { FacilityAvatar } from "../../components/FacilityAvatar";
import { SHIFT_DISCOVERY_WORKPLACE_OPEN_SHIFTS_MODAL_PATH } from "../../paths";
import { OpenShiftsList } from "../../Shift/Open/List";
import { useShiftModalsDataContext } from "../../useShiftModalsDataContext";
import { ShiftDiscoveryWorkplaceOpenShiftsEmptyState } from "./EmptyState";
import { useWorkplaceOpenShiftsData } from "./useWorkplaceOpenShiftsData";

interface WorkplaceOpenShiftsModalProps {
  modalState: UseModalState;
  workplace: Facility;
}

export function WorkplaceOpenShiftsModal(props: WorkplaceOpenShiftsModalProps) {
  const { modalState, workplace } = props;
  const { name, userId: workplaceId } = workplace;

  const {
    data: { filteredWorkplaceShifts, workplacesMap, offersMap },
    isError: openShiftsIsError,
    isLoading: openShiftsIsLoading,
    isSuccess: openShiftsIsSuccess,
  } = useWorkplaceOpenShiftsData(String(workplaceId));

  const { setBaseNavigationPathOverride } = useShiftModalsDataContext();

  /**
   * When this modal is opened, we want to override the base navigation path
   * so that when shift modals are opened from here, they are rendered on top of this workplace modal
   */
  useEffect(() => {
    const path = generatePath(SHIFT_DISCOVERY_WORKPLACE_OPEN_SHIFTS_MODAL_PATH, {
      workplaceId: String(workplaceId),
    });

    setBaseNavigationPathOverride(path);

    return () => {
      setBaseNavigationPathOverride(undefined);
    };
  }, [setBaseNavigationPathOverride, workplaceId]);

  return (
    <FullScreenDialog modalState={modalState} variant="primary">
      <DialogContent sx={{ px: 7, pt: 0, display: "flex", flexDirection: "column" }}>
        <DialogHeader />

        <Stack spacing={8} sx={{ flex: 1 }}>
          <Stack direction="row" spacing={7} alignItems="center">
            <FacilityAvatar facilityId={String(workplaceId)} size="medium" />

            <Title component="h3" variant="h5">
              {name}
            </Title>
          </Stack>

          {openShiftsIsSuccess && filteredWorkplaceShifts.length > 0 && (
            <CounterText count={filteredWorkplaceShifts.length}>
              <Title
                bold
                variant="h5"
                component="h4"
                sx={(theme) => ({ fontWeight: theme.typography.fontWeightMedium })}
              >
                Open Shifts
              </Title>
            </CounterText>
          )}

          <OpenShiftsList
            isError={openShiftsIsError}
            isLoading={openShiftsIsLoading}
            isSuccess={openShiftsIsSuccess}
            shifts={filteredWorkplaceShifts}
            workplacesMap={workplacesMap}
            offersMap={offersMap}
            loadingStateTexts={[
              "Finding you the best-paying shifts at this facility",
              "Analyzing shift opportunities just for you",
              "Your perfect shift is just a moment away",
              "Crunching the numbers to maximize your earnings",
              "Digging deep into facility schedules",
              "Searching high and low for the best shifts at this location",
              "Unlocking your next great opportunity at this facility",
            ]}
            renderEmptyState={() => <ShiftDiscoveryWorkplaceOpenShiftsEmptyState />}
          />
        </Stack>
      </DialogContent>

      <DialogFooter
        withShadow
        orientation="horizontal"
        backgroundColor={(theme) => theme.palette.background.tertiary}
        onClose={() => {
          modalState.closeModal();
        }}
      />
    </FullScreenDialog>
  );
}
