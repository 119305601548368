import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack, type Theme } from "@mui/material";
import { type FacilityNote } from "@src/appV2/Facilities/types";
import { type ComponentProps } from "react";

import { AsOfDateText } from "../../components/AsOfDateText";
import { LateCancellationText } from "./LateCancellationText";
import { SentHomeChargesText } from "./SentHomeChargesText";

// TODO: Export TextProps from @clipboard-health/ui-react
interface CancellationPoliciesSectionProps {
  validAsOfDate: Date;
  cancellationWorkplaceNote?: FacilityNote["note"];
  lateCancellationPeriod?: number;
  lateCancellationFeeHours?: number;
  sentHomeChargeHours?: number;
  textVariant?: ComponentProps<typeof Text>["variant"];
  color?: (theme: Theme) => string | undefined;
}

export function CancellationPoliciesSection(props: CancellationPoliciesSectionProps) {
  const {
    validAsOfDate,
    cancellationWorkplaceNote,
    lateCancellationPeriod,
    lateCancellationFeeHours,
    sentHomeChargeHours,
    color: propsColor,
    textVariant = "body2",
  } = props;

  const color: CancellationPoliciesSectionProps["color"] = (theme) => {
    if (isDefined(propsColor)) {
      return propsColor(theme);
    }

    return theme.palette.text.secondary;
  };

  return (
    <Stack spacing={3}>
      <AsOfDateText date={validAsOfDate} sx={{ paddingBottom: 4 }} />

      <LateCancellationText
        color={color}
        lateCancellationPeriod={lateCancellationPeriod ?? 0}
        lateCancellationFeeHours={lateCancellationFeeHours ?? 0}
        variant={textVariant}
      />
      <SentHomeChargesText
        color={color}
        sentHomeChargeHours={sentHomeChargeHours ?? 0}
        variant={textVariant}
      />

      {isDefined(cancellationWorkplaceNote) && (
        <Text color={color} variant={textVariant}>
          {cancellationWorkplaceNote}
        </Text>
      )}
    </Stack>
  );
}
