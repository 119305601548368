import { filterOpenShifts } from "../../Filters/filterOpenShifts";
import { useShiftDiscoveryUserFiltersContext } from "../../Filters/useUserFiltersContext";
import { useListViewOpenShiftsData } from "../../ListView/useListViewOpenShiftsData";

export function useWorkplaceOpenShiftsData(workplaceId: string) {
  const { license, shiftBookingType, shiftTimeSlot, distance } =
    useShiftDiscoveryUserFiltersContext();

  // TODO: Handle the case where we should be showing map view shifts away from home address
  // In that case, we'll need to pass co-ordinates from here that override the home address when fetching shifts
  const {
    shifts = [],
    workplacesMap = new Map(),
    offersMap = new Map(),
    ...openShiftsDataResult
  } = useListViewOpenShiftsData({
    distance,
    license,
  });

  const openShifts = shifts.filter(
    (shift) => shift.relationships.workplace.data.id === workplaceId
  );

  return {
    data: {
      filteredWorkplaceShifts: filterOpenShifts(
        { shifts: openShifts, workplacesMap },
        {
          // We only apply shift-specific filters (booking type and period) here, not workplace filters
          // like rating. This ensures we show all shifts for the selected workplace.
          // We want to avoid the case where workplace filters would normally hide them in the main list view.
          // When we support deeplinks in the future for opening this modal, we'll need to add an endpoint
          // for that purpose, remove distance based filtering and retain this logic so that we don't filter
          // out all of the facility shifts.
          shiftBookingType,
          shiftTimeSlot,
          dates: [],
        }
      ),
      unfilteredWorkplaceShifts: openShifts,
      workplacesMap,
      offersMap,
    },
    ...openShiftsDataResult,
  };
}
